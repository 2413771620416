import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from "../components/Layout"
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import PageWrapper from "../components/PageWrapper"
import MasonryGallery from '../components/MasonryGallery'
import CoverMedia from '../components/CoverMedia'
import { PageTitle, ItemTitle } from '../components/PageTitles'
import Pagination from '../components/Pagination'

export default function ArtistArchive({ data, pageContext }) {

    return (
        <Layout>
             {/* <Seo title={`Artists`} description={`Current roster of Lighter Than Air artists, under management.`} /> */}
             <PageWrapper scrollData={data.allWpArtist.nodes}>
             <PageTitle className={`hidden`}>MGMT. Artists</PageTitle>
             <MasonryGallery renderAs={`ul`} className={`p-0 -m-1.5 lg:-m-5 xl:-m-7 xl:!-mt-14 lg:!-mt-8 -mt-4`}>
                  
                {
                        data.allWpArtist.edges.map(artist => {

                            return  (
                                <li className={`w-1/2 md:w-1/3 m-0 p-1.5 lg:p-5 xl:p-7`} key={artist.node.id}>
                                    <Link to={`/artists/${artist.node.slug}`} title={artist.node.title}>
                                    {artist.node.featuredImage &&
            
                                            <CoverMedia itemData={artist.node} className={`transition-all duration-200 hover:skew-y-1 hover:skew-x-1 hover:scale-[0.97] full-cover`} />
                                        }

                                        <ItemTitle className={`!mt-1`}>{artist.node.title}</ItemTitle>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </MasonryGallery>
                <Pagination context={pageContext} nextTitle={`Next`} prevTitle={`Prev`} className={`lg:max-w-[50%] lg:mx-auto xl:max-w-fit`} />
             </PageWrapper>

        </Layout>
    )
}

export const Head = () => <Seo2 title={`Artists`} description={`Current roster of Lighter Than Air artists, under management.`} />

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    allWpArtist(
        skip: $skip,
        limit: $limit
        sort: {fields: slug, order: ASC}
        filter: { status: { eq: "publish" }, featuredImageDatabaseId: {ne: null}}
    ) {
        edges {
            node {
              slug
              title
              id
              featuredImageDatabaseId
              status
              streamingLinks {
                  youtubeartist
                  spotifyartist
                  soundcloudartist
                  pandoraartist
                  fieldGroupName
                  appleartist
                  amazonartist
                }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
          nodes {
              title
          }
    }
}
`